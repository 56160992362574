@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
  --text-font: 'Poppins', sans-serif;
  --primary-bg-color: #EEEEEE;
  --secondary-bg-color: #575969;
}

.App {
  font-family: var(--text-font);
  text-align: center;
  background-color: var(--primary-bg-color);
  color: #fff;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
header {
  display: none;
}
header h1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #d9d9e3;
  margin: auto;
}
.menu button {
  background: none;
  border: none;
}
nav {
  display: none;
  background-color: #4c4f5f;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
}
.navItems {
  padding: 8px;
  background-color: #00404F;
  width: 100%;
  max-width: 340px;
}
.navPromptWrapper {
  padding: 10px;
  height: calc(100vh - 460px);
  overflow-y: auto;
}
.navPrompt {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  column-gap: 20px;
  margin: 5px 0;
  text-align: left;
  padding-left: 40px;
  padding-right: 40px;
}
.navPrompt a {
  display: flex;
  align-items: center;
  column-gap: 20px;
  text-decoration: none;
}
.navPrompt p {
  font-size: 14px;
  line-height: 20px;
  color: #ececf1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  margin: 0;
}

.hint {
  border-radius: 8px;;
  padding: 16px;
  color: #00404F;
  background-color: #ECF0F2;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.hintBox {
  color: #336772;
  background-color: #FFFFFF;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 0px rgba(201, 201, 201, 0.20), 0px 1px 3px 0px rgba(201, 201, 201, 0.20), 0px 5px 5px 0px rgba(201, 201, 201, 0.17), 0px 10px 6px 0px rgba(201, 201, 201, 0.10), 0px 18px 7px 0px rgba(201, 201, 201, 0.03), 0px 28px 8px 0px rgba(201, 201, 201, 0.00);
}

.hintSection {
  display: flex;
  justify-content: center;
  gap: 24px;
  align-self: stretch;
}

.hintBoxTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
}

.navPrompt span {
  animation: fadeInChar 1s ease-in forwards;
  opacity: 0;
}
#botMessage pre {
  margin: 0;
  padding: 0;
  text-indent: 0;
  white-space: pre-wrap;
  position: relative;
  /* top: -45px; */
}
pre {
  font-family: var(--text-font);
}
@keyframes fadeInChar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navCloseIcon {
  width: 20%;
  margin: 10px;
}
nav svg {
  float: left;
}
.sideMenu {
  border-radius: 24px;
  width: 280px;
  margin: 40px;
  background-color: #00404F;
}

.newChat {
  padding: 0px 40px;
  height: 48px;
  border-radius: 49px;
  background-color: #FFC700;
}

.newChat:hover {
  background-color: #EEEEEE; /* Green */
  border-radius: 49px;
}

.sideMenuButton {
  border: 1px solid hsl(0deg 0% 100% / 20%);
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
}
.navPrompt:hover,
.sideMenuButton:hover {
  cursor: pointer;
  background-color: hsla(240, 9%, 59%, 0.1);
}
.sideMenuButton span {
  font-size: 20px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 10px;
}
.chatBox {
  flex: 1;
  background-color: #EEEEEE;
  position: relative;
  line-height: 24px;
  color: #d1d5db;
  font-size: 16px;
  margin-right: 20px;
}
#introsection {
  text-align: left;
  padding: 20px;
}
#introsection h1 {
  padding-top: 20px;
  line-height: 33px;
}
#introsection h2 {
  font-size: 16px;
  font-weight: 400;
}
#introsection ul {
  list-style-type: square;
}
#introsection pre {
  margin: 0;
  display: inline;
}
.chatLogWrapper {
  height: 80vh;
  overflow-y: auto;
}

/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

::-moz-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

.chatPromptWrapper {
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  background-color: #FFFFFF;
  color: #00404F;
  border-radius: 8px;
  column-gap: 25px;
  box-shadow: 0px 0px 0px 0px rgba(201, 201, 201, 0.20), 0px 1px 3px 0px rgba(201, 201, 201, 0.20), 0px 5px 5px 0px rgba(201, 201, 201, 0.17), 0px 10px 6px 0px rgba(201, 201, 201, 0.10), 0px 18px 7px 0px rgba(201, 201, 201, 0.03), 0px 28px 8px 0px rgba(201, 201, 201, 0.00);
}

.userSVG {
  transform: scale(0.6);
}
.botMessageMainContainer {
  width: 100%;
  position: relative;
}
.navLinks {
  position: absolute;
  bottom: 50px;
}
.botMessageWrapper {
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  margin-top: 24px;
  margin-bottom: 24px;
  /* align-items: center; */
  background-color: #CCD9DC;
  color: #00404F;
  column-gap: 25px;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 0px rgba(201, 201, 201, 0.20), 0px 1px 3px 0px rgba(201, 201, 201, 0.20), 0px 5px 5px 0px rgba(201, 201, 201, 0.17), 0px 10px 6px 0px rgba(201, 201, 201, 0.10), 0px 18px 7px 0px rgba(201, 201, 201, 0.03), 0px 28px 8px 0px rgba(201, 201, 201, 0.00);
}
.stop-messgage {
  position: absolute;
  bottom: 10px;
  right: 100px;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 10%;
  border: 1px solid rgb(86, 88, 105);
  background-color: rgb(52, 53, 65);
  color: rgb(217, 217, 217);
  cursor: pointer;
  display: none;
}
.stop-messgage:hover {
  background-color: rgb(64, 65, 79);
  /* border: none; */
}
.errorMessage {
  color: #ef4444;
  font-size: 16px;
  line-height: 24px;
}

.openaiSVG {
  transform: scale(0.5);
}
#avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
  min-width: 36px;
}

.inputPromptWrapper {
  margin-top: 40px;
  left: 50%;
  right: 50%;
  bottom: 70px;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 200px;
  height: 55px;
  background-color: #FDFDFD;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  display: flex;
}
.inputPrompttTextarea {
  padding: 10px;
  flex: 1;
  resize: none;
  background-color: #FDFDFD;
  color: #838383;
  font-size: 16px;
  outline: none;
  border: none;
  overflow-y: hidden;
  border-radius: 8px;
}
.sendButton {
  background-color: #FDFDFD;
  margin: 5px;
  height: auto;
  position: initial;
}
.sendButton:hover {
  background-color: #FFC700;
}
.footer {
  position: absolute;
  bottom: 20px;
}
form button {
  background: rgb(65, 65, 78);
  border: none;
  position: absolute;
  top: 20%;
  right: 15px;
  width: 35px;
  height: 45px;
}

@media (hover: hover) {
  button:hover {
    cursor: pointer;
    background: #212023;
    border-radius: 20%;
  }
}

.loginContainer {
  background: var(--primary-bg-color);
  width: 100%;
  height: 100%;
  font-family: var(--text-font);
}

.loginContainerContent {
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.loginContainerContent svg {
  padding-bottom: 30px;
}

.loginContainer h1 {
  font-size: 30px;
  margin: 0;
}

.loginContainer p {
  font-size: 18px;
  line-height: 5px;
}

.loginButtonWrapper {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-top: 20px;
}

#loginButton {
  padding: 10px 25px;
  border-radius: 7px;
  color: #000000;
  background: #FFC700;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
#loginButton:hover {
  background-color: #e3e3e3;
}

@media screen and (min-width: 768px) {
  .inputPromptWrapper {
    position: absolute;
  }
}

@media screen and (max-width: 1024px) {
  .App {
    display: block;
  }
  header {
    display: flex;
    align-items: center;
    background: #353441;
    border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
    padding: 4px 0 0 12px;
  }
  nav {
    display: flex;
  }
  .hintSection {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
  .hideOnSmallScreen {
    display: none;
  }
  .sideMenu {
    display: none;
  }
  .chatBox {
    position: static;
  }
  .chatPromptWrapper {
    padding: 12px;
  }
  .botMessageWrapper {
    padding: 12px;
  }
  .stop-messgage {
    right: 5px;
    font-size: 13px;
    padding: 8px 15px;
  }
  .userSVG {
    transform: scale(0.5);
  }
  .openaiSVG {
    transform: scale(0.4);
  }
  #avatar {
    width: 30px;
    height: 30px;
  }
  #introsection {
    padding: 20px 20px 40px 20px;
    font-size: 14px;
  }
  #introsection h1,
  pre {
    font-size: 16px;
    white-space: pre-wrap;
  }
  #introsection h2 {
    font-size: 14px;
  }
}

@media screen and (min-width: 1536px) {
  .loginContainer h1 {
    font-size: 40px;
  }
  .loginContainer p {
    font-size: 25px;
  }
  #loginButton {
    font-size: 22px;
  }
}

.nicknameForm {
  display: grid;
  gap: 16px;
}

.nicknameForm p {
  font-size: 14px;
  font-weight: 700;
}

.nicknameForm input {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid black;
  text-align: center;
  transition: border-width 0.2s linear;
}

.nicknameForm input:hover { 
  border-width: 2px;
}

.nicknameForm input:focus { 
  border-width: 2px;
}

.nicknameForm button {
  min-width: 275px;
  border: none;
  border-radius: 5px;
  padding: 12px;
  margin-top: 5px;
  background-color: #FFC700;
  color: #000000;
  cursor: pointer;
  position: static;
}

.nicknameForm button:hover {
  background: #e3e3e3;
}